<template>
  <div>
    <el-form :model="form"
             label-width="50px"
             style="margin-bottom:10px">
      <el-row :gutter="10">
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-form-item label="版块:">
            <el-select v-model="form.discuss_board"
                       @change="search"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in plateList"
                         :key="item.board_id"
                         :label="item.board_name"
                         :value="item.board_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-select v-model="form.discuss_post_type"
                     style="width:100%"
                     @change="search"
                     placeholder="请选择">
            <el-option v-for="item in types"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-col>
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-input clearable
                    placeholder="请输入内容"
                    v-model="form.search"
                    @keyup.enter.native="search"
                    style="width:100%" />
        </el-col>
        <el-col :lg="5"
                :md="12"
                :sm="12"
                :xl="4"
                :xs="12">
          <el-input clearable
                    placeholder="请输入题目ID"
                    v-model="form.question_id"
                    @keyup.enter.native="search"
                    style="width:100%" />
        </el-col>

      </el-row>
      <el-row :gutter="10">
        <el-col :lg="15"
                :md="24"
                :sm="12"
                :xl="12"
                :xs="24">
          <el-button type="primary"
                     @click="search">搜索</el-button>
          <el-button type="primary"
                     @click="fatie">我要发帖</el-button>
          <!-- <el-button type="primary"
                     v-if="is_discuss_admin == 1"
                     @click="toAudit">未审核({{count_review}})</el-button> -->
          <!-- <BASICCOMMON ref="BasicRef"
                       @setList="setList"
                       @refresh="refresh"
                       :historyData="'forum'" /> -->
        </el-col>
      </el-row>
    </el-form>

    <el-table border
              stripe
              highlight-current-row
              v-loading="tableLoading"
              :max-height="$store.state.tableHeight + 80"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="discuss_id"
                       v-if="tableColumns[0].isShow"
                       align="center"
                       label="ID" />
      <el-table-column prop="board_name"
                       v-if="tableColumns[1].isShow"
                       align="center"
                       label="版块" />
      <el-table-column prop="discuss_title"
                       v-if="tableColumns[2].isShow"
                       align="center"
                       show-overflow-tooltip
                       label="标题">
        <template slot-scope="{row}">
          <div class="line">
            {{row.discuss_title}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop=""
                       v-if="tableColumns[3].isShow"
                       align="center"
                       width="300"
                       show-overflow-tooltip
                       label="内容">
        <template slot-scope="{row}">
          <div class="line point"
               @click="toDetails(row)">
            {{row.discuss_content}}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="question_id"
                       v-if="tableColumns[4].isShow"
                       align="center"
                       label="题目ID" />
      <el-table-column prop="discuss_time"
                       v-if="tableColumns[5].isShow"
                       align="center"
                       show-overflow-tooltip
                       sortable
                       width="200"
                       label="发帖时间  " />
      <el-table-column prop="discuss_re_times"
                       v-if="tableColumns[6].isShow"
                       align="center"
                       label="回复数" />
      <el-table-column prop="discuss_see_times"
                       v-if="tableColumns[7].isShow"
                       align="center"
                       label="查看数" />
      <el-table-column prop="discuss_upvote_times"
                       v-if="tableColumns[8].isShow"
                       align="center"
                       label="点赞数" />
      <el-table-column prop="last_re_time"
                       width="200"
                       v-if="tableColumns[9].isShow"
                       show-overflow-tooltip
                       align="center"
                       label="最后回帖时间" />
      <el-table-column v-if="tableColumns[10].isShow"
                       align="center"
                       label="已结帖">
        <template slot-scope="{row}">
          {{row.is_over == 1 ?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column align="center"
                       v-if="tableColumns[11].isShow"
                       width="120"
                       fixed="right">
        <template slot="header">
          <i class="el-icon-setting"
             slot="header"
             @click="showController"></i>
          操作
        </template>
        <template slot-scope="{row}">
          <div class="operyion_btn_icon">
            <el-tooltip effect="dark"
                        content="查看"
                        placement="top-start">
              <img src="@/assets/icons/icon-look@2x.png"
                   @click="toDetails(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span>
            <el-tooltip effect="dark"
                        content="删除"
                        placement="top-start">
              <img src="@/assets/icons/icon-delete@2x.png"
                   @click="delRow(row)"
                   alt="">
            </el-tooltip>
            <span style="width:20px;height:20px"></span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Shuttle ref="ShuttleRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <addForm ref="addFormRef" />
  </div>
</template>

<script>
import addForm from './components/add.vue'

export default {
  data () {
    return {
      form: {
        discuss_board: '',
        discuss_post_type: 0,
        question_id: '',
        search: ''
      },
      is_discuss_admin: 0,
      plateList1: [],
      options: [],
      tableData: [],
      tableLoading: false,
      page: 1,
      size: 10,
      totalElements: 0,
      showList: [],
      plateList: [],
      count_review: 0,
      types: [
        { label: '全部', id: 0 },
        { label: '我的贴子', id: 1 },
        { label: '我的回复', id: 2 },
        { label: '我的点赞', id: 3 },
      ],
      pageName: 'cz_answer_forum_admin',
      tableColumns: [
        { label: 'ID', isShow: true },
        { label: '版块', isShow: true },
        { label: '标题', isShow: true },
        { label: '内容', isShow: true },
        { label: '题目ID', isShow: true },
        { label: '发帖时间', isShow: true },
        { label: '回复数', isShow: true },
        { label: '查看数', isShow: true },
        { label: '点赞数', isShow: true },
        { label: '最后回帖时间', isShow: true },
        { label: '已结帖', isShow: true },
        { label: '操作', isShow: true },
      ]
    }
  },
  components: {
    addForm,
  },
  mounted () {
    this.getPlateList()
  },
  created () {
    if (window.localStorage.getItem(this.pageName)) {
      this.tableColumns = JSON.parse(window.localStorage.getItem(this.pageName))
    }
  },
  methods: {
    fatie () {
      let form = {
        board_id: '',
        discuss_title: '',
        discuss_content: '',
        is_public: 1
      }
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      let edu_school_id = userInfo.edu_school_id
      this.$refs.addFormRef.edu_school_id = edu_school_id
      this.$refs.addFormRef.form = form
      this.$refs.addFormRef.plateList = this.plateList1
      this.$refs.addFormRef.dialogVisible = true
    },
    toDetails (row) {
      this.$router.push('/answer/forum/details?discuss_id=' + row.discuss_id)
    },
    getPlateList () {
      this.$http({
        url: '/api/v1/discuss/board_lst',
        method: 'get',
      }).then(res => {
        this.plateList1 = JSON.parse(JSON.stringify(res.data.list))
        this.plateList = res.data.list
      })
    },
    initData (page, limit) {
      this.form.page = page
      this.form.limit = limit
      this.tableLoading = true
      this.$http({
        url: '/api/v1/discuss/lst',
        method: 'get',
        params: this.form
      }).then(res => {
        this.tableLoading = false
        this.totalElements = res.data.count
        this.is_discuss_admin = res.data.is_discuss_admin
        this.count_review = res.data.count_review
        this.tableData = res.data.list
      })
    },
    delRow (item) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/discuss/del',
          method: 'post',
          data: {
            discuss_id: item.discuss_id
          }
        }).then(res => {
          console.log('discuss_id', item);
          this.$notify({
            title: '提示',
            message: '删除成功!',
            type: 'success'
          });
          this.refresh()
        })
      })
    },
    toAudit () {
      this.$refs.auditFormRef.getTieziInfo()
      this.$refs.auditFormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    showController () {
      this.$refs.ShuttleRef.columns = JSON.parse(JSON.stringify(this.tableColumns))
      this.$refs.ShuttleRef.dialogVisible = true
    },
    getColumns (arr) {
      this.tableColumns = arr
      window.localStorage.setItem(this.pageName, JSON.stringify(arr))
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.point {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0;
}
.el-col {
  margin-bottom: 5px;
}
</style>